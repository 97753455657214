import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Artist from "../components/artist"
import "../components/artist.module.css"
import Painting from "../components/painting"
import loadable from "@loadable/component"

const VanGoghComponent = loadable(() => import("../components/vangoghComponent"))

const VanGogh = ({ data }) => {
  return (

    <Layout>
      <SEO
        title="VanGogh"
        description="VanGogh's life and paintings."
        image="/logo.png"
        pathname="/vangogh"

      />

      <Artist
        bubble2={{ "--bubble2": "#77b29e" }}
        name={"Vincent Van Gogh"}
        dates={"(1853-1890)"}
        descrip={"You may know Van Gogh for being mentally unstable and cutting off part of his ear after an argument with Paul Gauguin.  But there was a lot more to Van Gogh than being just a tortured Dutch artist.  "}
      />

      <Painting
        leftPanel={{ backgroundColor: "#194b95" }}
        h1Title={"Irises"}
        sectionDescription={"He is well known for his vivid and colorful paintings of flowers, landscapes, and portraits.  Van Gogh painted quickly, with big brush strokes and a lot of passion. He was self-taught and inspired the style of art called expressionism. "}
        paintingFixed={data.irises.childImageSharp.fixed}
        alt={"Irises painting"}
        rtPanel={{ backgroundColor: "#77b29e" }}
        // Mobile
        paintingFluid={data.irisessm.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#194b95" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "rgb(42 77 83)" }}
        h1Title={"Self-portrait"}
        sectionDescription={"Before Van Gogh became a painter, he tried different jobs, including schoolmaster, bookstore clerk, and preacher.  He also worked as an art dealer and was exposed to different artists and styles of painting.  His brother, Theo, convinced him to become a painter at age 27."}
        paintingFixed={data.selfportrait.childImageSharp.fixed}
        alt={"Self-portrait"}
        rtPanel={{ backgroundColor: "rgb(234 233 168)" }}
        paintingFluid={data.selfportraitmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "rgb(42 77 83)" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "rgb(31 39 36)" }}
        h1Title={"The Potato Eaters"}
        sectionDescription={"When Van Gogh first started painting, he used dark colors to show the hardship and suffering of his subjects.  After learning about impressionism, his paintings brightened."}
        paintingFixed={data.thepotatoeaters.childImageSharp.fixed}
        alt={"The Potato Eaters"}
        rtPanel={{ backgroundColor: "rgb(68 73 41)" }}
        paintingFluid={data.thepotatoeaterssm.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "rgb(31 39 36)" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "rgb(68 132 154)" }}
        h1Title={"Almond Blossom"}
        sectionDescription={"Through his paintings, Van Gogh was able to express inner spirituality and how he viewed nature in a dramatic, imaginative, and emotional way.  He once said, \"real painters do not paint things as they are... they paint them as they themselves feel them to be.\""}
        paintingFixed={data.almondblossom.childImageSharp.fixed}
        alt={"Almond Blossom"}
        rtPanel={{ backgroundColor: "rgb(150 193 137)" }}
        // Mobile
        paintingFluid={data.almondblossomsm.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "rgb(68 132 154)" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#212d5f" }}
        h1Title={"Starry Night"}
        sectionDescription={"Starry Night, probably his most famous work, was painted in an asylum in France. He admitted himself there after his 1888 nervous breakdown in which he cut his ear. The painting shows the view from his window at the asylum."}
        paintingFixed={data.starrynight.childImageSharp.fixed}
        alt={"Starry Night"}
        rtPanel={{ backgroundColor: "#f3ec94" }}
        // Mobile
        paintingFluid={data.starrynightsm.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#212d5f" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#272a31" }}
        h1Title={"The Portrait of Doctor Gachet"}
        sectionDescription={"During his lifetime Van Gogh sold only one painting.  Now his paintings sell for millions.  In 1990, The Portrait of Doctor Gachet was sold in three minutes at an auction for $82.5 million.  "}
        paintingFixed={data.drpaulgachet.childImageSharp.fixed}
        alt={"The Portrait of Doctor Gachet"}
        rtPanel={{ backgroundColor: "#c34d03" }}
        // Mobile
        paintingFluid={data.drpaulgachetsm.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#272a31" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#132d54" }}
        h1Title={"Self-portrait with Injured Ear "}
        sectionDescription={"Not long after he painted The Portrait of Doctor Gachet, Vincent Van Gogh suffered a gunshot wound to the abdomen. He died in his bed two days later with his brother, Theo, at his side.  He was 37 years old.  Most people at the time believed he committed suicide, but it was not ruled out that he had been shot by unruly local teenagers."}
        paintingFixed={data.earportrait.childImageSharp.fixed}
        alt={"Self-portrait with Injured Ear "}
        rtPanel={{ backgroundColor: "#6dab89" }}
        // Mobile
        paintingFluid={data.earportraitsm.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#132d54" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#4a1728" }}
        h1Title={"Sunflowers "}
        sectionDescription={"In less than 10 years, Vincent Van Gogh painted almost 900 paintings.  \"Love many things, for therein lies the true strength, and whosoever loves much performs much, and can accomplish much, and what is done in love is done well.\" - Vincent Van Gogh."}
        paintingFixed={data.sunflowers.childImageSharp.fixed}
        alt={"Sunflowers painting"}
        rtPanel={{ backgroundColor: "#dfcd83" }}
        // Mobile
        paintingFluid={data.sunflowerssm.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#4a1728" }}
      />

<VanGoghComponent/>

    </Layout>
  )
}

export default VanGogh

export const query = graphql`
  {
 irises: file(relativePath: { eq: "paintings/vangogh/irises.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     irisessm: file(relativePath: { eq: "paintings/vangogh/irises.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
     selfportrait: file(relativePath: { eq: "paintings/vangogh/selfportrait.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         selfportraitmobile: file(relativePath: { eq: "paintings/vangogh/selfportraitmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         thepotatoeaters: file(relativePath: { eq: "paintings/vangogh/thepotatoeaters.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         thepotatoeaterssm: file(relativePath: { eq: "paintings/vangogh/thepotatoeaters.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         almondblossom: file(relativePath: { eq: "paintings/vangogh/almondblossom.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         almondblossomsm: file(relativePath: { eq: "paintings/vangogh/almondblossom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         starrynight: file(relativePath: { eq: "paintings/vangogh/starrynight.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         starrynightsm: file(relativePath: { eq: "paintings/vangogh/starrynight.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         drpaulgachet: file(relativePath: { eq: "paintings/vangogh/drpaulgachet.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         drpaulgachetsm: file(relativePath: { eq: "paintings/vangogh/drpaulgachetmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         earportrait: file(relativePath: { eq: "paintings/vangogh/earportrait.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         earportraitsm: file(relativePath: { eq: "paintings/vangogh/earportraitmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
         sunflowers: file(relativePath: { eq: "paintings/vangogh/sunflowers.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         sunflowerssm: file(relativePath: { eq: "paintings/vangogh/sunflowersmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`